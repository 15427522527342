import Home from './home'
import { createBrowserRouter,} from "react-router-dom";

const AppRouter = createBrowserRouter([
  {
    path: "/",
    element: <Home/>,
  },


]);

export default AppRouter;