import React, {
  useState,
  useContext,
  useEffect,
  useRef,
} from "react";


const Home = () => {

    return (
        <div className="nk-app-root ">
                <header className="nk-header has-mask">
                    <div className="nk-mask bg-gradient-a"></div>
                    <div className="nk-mask bg-pattern-dot bg-blend-top"></div>
                    <div className="nk-header-main nk-menu-main will-shrink is-transparent ignore-mask">
                        <div className="container">
                            <div className="nk-header-wrap">
                                <div className="nk-header-logo">
                                    <a href="/" className="logo-link">
                                        <div className="logo-wrap">
                                            <img className="logo-img logo-dark" src="images/logo-dark.png" srcset="images/logo-dark2x.png 2x" alt=""/>
                                        </div>
                                    </a>
                                </div>
                                <div className="nk-header-toggle">
                                    <button className="btn btn-light btn-icon header-menu-toggle">
                                        <em className="icon ni ni-menu"></em>
                                    </button>
                                </div>
                                <nav className="nk-header-menu nk-menu">
                                    <ul className="nk-menu-list mx-auto">
                                        <li className="nk-menu-item has-dropdown">
                                            <a href="#" className="nk-menu-link nk-menu-toggle">
                                                <span className="nk-menu-text">Home</span>
                                            </a>
                                            <div className="nk-menu-dropdown">
                                                <ul className="nk-menu-sub">
                                                    <li className="nk-menu-item">
                                                        <a className="nk-menu-link" href="index.html">Home S1</a>
                                                    </li>
                                                    <li className="nk-menu-item">
                                                        <a className="nk-menu-link" href="index-s2.html">Home S2</a>
                                                    </li>
                                                    <li className="nk-menu-item">
                                                        <a className="nk-menu-link" href="index-s3.html">Home S3</a>
                                                    </li>
                                                    <li className="nk-menu-item">
                                                        <a className="nk-menu-link" href="index-s4.html">Home S4 <div className="badge bg-primary ms-2 me-n4 px-2 rounded-pill">New</div></a>
                                                    </li>
                                                    <li className="nk-menu-item">
                                                        <a className="nk-menu-link" href="index-s5.html">Home S5 <div className="badge bg-primary ms-2 me-n4 px-2 rounded-pill">New</div></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li className="nk-menu-item">
                                            <a href="usecase.html" className="nk-menu-link">
                                                <span className="nk-menu-text">Use Cases</span>
                                            </a>
                                        </li>
                                        <li className="nk-menu-item">
                                            <a href="features.html" className="nk-menu-link">
                                                <span className="nk-menu-text">Features</span>
                                            </a>
                                        </li>
                                        <li className="nk-menu-item has-dropdown">
                                            <a href="#" className="nk-menu-link nk-menu-toggle">
                                                <span className="nk-menu-text">Pages</span>
                                            </a>
                                            <div className="nk-menu-dropdown nk-menu-mega">
                                                <div className="nk-menu-mega-wrap">
                                                    <ul className="nk-menu-sub">
                                                        <li className="nk-menu-item">
                                                            <a className="nk-menu-link" href="about.html">About</a>
                                                        </li>
                                                        <li className="nk-menu-item">
                                                            <a className="nk-menu-link" href="usecase.html">Use Case</a>
                                                        </li>
                                                        <li className="nk-menu-item">
                                                            <a className="nk-menu-link" href="usecase-details.html">Use Case Details <div className="badge bg-primary ms-2 me-n4 px-2 rounded-pill">New</div></a>
                                                        </li>
                                                        <li className="nk-menu-item">
                                                            <a className="nk-menu-link" href="usecase-s2.html">Use Case S2</a>
                                                        </li>
                                                        <li className="nk-menu-item">
                                                            <a className="nk-menu-link" href="usecase-details-s2.html">Use Case Details S2 <div className="badge bg-primary ms-2 me-n4 px-2 rounded-pill">New</div></a>
                                                        </li>
                                                        <li className="nk-menu-item">
                                                            <a className="nk-menu-link" href="pricing.html">Pricing</a>
                                                        </li>
                                                        <li className="nk-menu-item">
                                                            <a className="nk-menu-link" href="pricing-s2.html">Pricing S2</a>
                                                        </li>
                                                        <li className="nk-menu-item">
                                                            <a className="nk-menu-link" href="blog.html">Blog</a>
                                                        </li>
                                                    </ul>
                                                    <ul className="nk-menu-sub">
                                                        <li className="nk-menu-item">
                                                            <a className="nk-menu-link" href="blog-single.html">Blog Single</a>
                                                        </li>
                                                        <li className="nk-menu-item">
                                                            <a className="nk-menu-link" href="features.html">Features</a>
                                                        </li>
                                                        <li className="nk-menu-item">
                                                            <a className="nk-menu-link" href="contact.html">Contact</a>
                                                        </li>
                                                        <li className="nk-menu-item">
                                                            <a className="nk-menu-link" href="contact-s2.html">Contact S2</a>
                                                        </li>
                                                        <li className="nk-menu-item">
                                                            <a className="nk-menu-link" target="_blank" href="login.html">Login</a>
                                                        </li>
                                                        <li className="nk-menu-item">
                                                            <a className="nk-menu-link" target="_blank" href="login-s2.html">Login S2</a>
                                                        </li>
                                                        <li className="nk-menu-item">
                                                            <a className="nk-menu-link" target="_blank" href="register.html">Register</a>
                                                        </li>
                                                        <li className="nk-menu-item">
                                                            <a className="nk-menu-link" target="_blank" href="register-s2.html">Register S2</a>
                                                        </li>
                                                    </ul>
                                                    <ul className="nk-menu-sub">
                                                        <li className="nk-menu-item">
                                                            <a className="nk-menu-link" target="_blank" href="reset.html">Reset</a>
                                                        </li>
                                                        <li className="nk-menu-item">
                                                            <a className="nk-menu-link" target="_blank" href="reset-s2.html">Reset S2</a>
                                                        </li>
                                                        <li className="nk-menu-item">
                                                            <a className="nk-menu-link" target="_blank" href="404.html">404 Error</a>
                                                        </li>
                                                        <li className="nk-menu-item">
                                                            <a className="nk-menu-link" target="_blank" href="404-s2.html">404 Error S2</a>
                                                        </li>
                                                        <li className="nk-menu-item">
                                                            <a className="nk-menu-link" target="_blank" href="504.html">504 Error</a>
                                                        </li>
                                                        <li className="nk-menu-item">
                                                            <a className="nk-menu-link" target="_blank" href="504-s2.html">504 Error s2</a>
                                                        </li>
                                                        <li className="nk-menu-item">
                                                            <a className="nk-menu-link" target="_blank" href="terms-condition.html">Terms &amp; Condition</a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="nk-menu-item">
                                            <a href="pricing.html" className="nk-menu-link">
                                                <span className="nk-menu-text">Pricing</span>
                                            </a>
                                        </li>
                                    </ul>
                                    <ul className="nk-menu-buttons flex-lg-row-reverse">
                                        <li><a href="//develop.teneut.com/sign-up" className="btn btn-primary">Sign Up </a></li>
                                        <li><a className="link link-dark" href="//develop.teneut.com/sign-in">Login </a></li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                    <div className="nk-hero pt-xl-5">
                        <div className="container">
                            <div className="row justify-content-center text-center">
                                <div className="col-lg-11 col-xl-9 col-xxl-8">
                                    <div className="nk-hero-content py-5 py-lg-6">
                                        <h1 className="title mb-3 mb-lg-4">Transform Your Hiring Process with Seamless Video Interviews</h1>
                                        <p className="lead px-md-8 px-lg-6 mb-4 mb-lg-5">Our innovative video interviewing platform allows you to conduct initial interviews with candidates remotely, saving you time and resources.</p>
                                        <ul className="btn-list btn-list-inline">
                                            <li><a href="#" className="btn btn-secondary btn-lg"><span>Start interviewing for free</span></a></li>
                                        </ul>
                                        <p className="sub-text mt-2">No credit card required</p>
                                    </div>
                                    <div className="nk-hero-gfx">
                                        <img className="w-100 rounded-top-4" src="images/gfx/banner/a.jpg" alt=""/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <main className="nk-pages">
                    <section className="section section-bottom-0 has-shape">
                        <div className="nk-shape bg-shape-blur-a mt-8 start-50 top-0 translate-middle-x"></div>
                        <div className="container">
                            <div className="section-head">
                                <div className="row justify-content-center text-center">
                                    <div className="col-lg-9 col-xl-8 col-xxl-7">
                                        <h6 className="overline-title text-primary">Get started for free</h6>
                                        <h2 className="title">Generate Questions using AI</h2>
                                        <p className="lead">Give our AI a few descriptions and we'll automatically create interview questions, job descriptions and more for you within just few seconds.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="section-content">
                                <div className="row text-center g-gs">
                                    <div className="col-md-6 col-xl-4">
                                        <div className="card rounded-4 border-0 shadow-tiny h-100">
                                            <div className="card-body">
                                                <div className="feature">
                                                    <div className="feature-media">
                                                        <div className="media media-middle media-xl text-bg-primary-soft rounded-4">
                                                            <em className="icon ni ni-book-read"></em>
                                                        </div>
                                                    </div>
                                                    <div className="feature-text">
                                                        <h4 className="title">Blog Post &amp; Articles</h4>
                                                        <p>Generate optimized blog post and articles to get organic traffic - making you visible to the world. </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-xl-4">
                                        <div className="card rounded-4 border-0 shadow-tiny h-100">
                                            <div className="card-body">
                                                <div className="feature">
                                                    <div className="feature-media">
                                                        <div className="media media-middle media-xl text-bg-primary-soft rounded-4">
                                                            <em className="icon ni ni-card-view"></em>
                                                        </div>
                                                    </div>
                                                    <div className="feature-text">
                                                        <h4 className="title">Product Description</h4>
                                                        <p>Create a perfect description for your products to engage your customers to click and buy.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-xl-4">
                                        <div className="card rounded-4 border-0 shadow-tiny h-100">
                                            <div className="card-body">
                                                <div className="feature">
                                                    <div className="feature-media">
                                                        <div className="media media-middle media-xl text-bg-primary-soft rounded-4">
                                                            <em className="icon ni ni-facebook-f"></em>
                                                        </div>
                                                    </div>
                                                    <div className="feature-text">
                                                        <h4 className="title">Social Media Ads</h4>
                                                        <p>Create ads copies for your social media - make an impact with your online marketing campaigns.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-xl-4">
                                        <div className="card rounded-4 border-0 shadow-tiny h-100">
                                            <div className="card-body">
                                                <div className="feature">
                                                    <div className="feature-media">
                                                        <div className="media media-middle media-xl text-bg-primary-soft rounded-4">
                                                            <em className="icon ni ni-grid-plus"></em>
                                                        </div>
                                                    </div>
                                                    <div className="feature-text">
                                                        <h4 className="title">Product Benefits</h4>
                                                        <p>Create a bullet point list of your product benefits that appeal to your customers to purchase.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-xl-4">
                                        <div className="card rounded-4 border-0 shadow-tiny h-100">
                                            <div className="card-body">
                                                <div className="feature">
                                                    <div className="feature-media">
                                                        <div className="media media-middle media-xl text-bg-primary-soft rounded-4">
                                                            <em className="icon ni ni-layout2"></em>
                                                        </div>
                                                    </div>
                                                    <div className="feature-text">
                                                        <h4 className="title">Landing Page Content</h4>
                                                        <p>Write very attractive headlines, slogans or paragraph for your landing page of your website.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-xl-4">
                                        <div className="card rounded-4 border-0 shadow-tiny h-100">
                                            <div className="card-body">
                                                <div className="feature">
                                                    <div className="feature-media">
                                                        <div className="media media-middle media-xl text-bg-primary-soft rounded-4">
                                                            <em className="icon ni ni-loader"></em>
                                                        </div>
                                                    </div>
                                                    <div className="feature-text">
                                                        <h4 className="title">Suggest Improvements</h4>
                                                        <p>Need to improve your existing content? Our AI will rewrite and improve the content for you.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="section has-mask">
                        <div className="nk-mask bg-pattern-dot bg-blend-around mt-10p mb-3"></div>
                        <div className="container">
                            <div className="section-head">
                                <div className="row justify-content-center text-center">
                                    <div className="col-lg-9 col-xl-8 col-xxl-7">
                                        <h6 className="overline-title text-primary">How it works</h6>
                                        <h2 className="title">Instruct to our AI and generate copy</h2>
                                        <p className="lead">Give our AI a few descriptions and we'll automatically create blog articles, product descriptions and more for you within just few second.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="section-content">
                                <div className="row g-gs">
                                    <div className="col-lg-4">
                                        <div className="feature feature-inline">
                                            <div className="feature-text me-auto">
                                                <h4 className="title">Select writing template</h4>
                                                <p>Simply choose a template from available list to write content for blog posts, landing page, website content etc. </p>
                                            </div>
                                            <div className="feature-decoration flex-shrink-0 ms-4">
                                                <img src="images/number/1.png" alt=""/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="feature feature-inline">
                                            <div className="feature-text me-auto">
                                                <h4 className="title">Describe your topic</h4>
                                                <p>Provide our AI content writer with few sentences on what you want to write, and it will start writing for you. </p>
                                            </div>
                                            <div className="feature-decoration flex-shrink-0 ms-4">
                                                <img src="images/number/2.png" alt=""/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="feature feature-inline">
                                            <div className="feature-text me-auto">
                                                <h4 className="title">Generate quality content</h4>
                                                <p>Our powerful AI tools will generate content in few second, then you can export it to wherever you need.</p>
                                            </div>
                                            <div className="feature-decoration flex-shrink-0 ms-4">
                                                <img src="images/number/3.png" alt=""/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="section-actions text-center">
                                <ul className="btn-list btn-list-inline gx-gs gy-3">
                                    <li><a href="#" className="btn btn-primary btn-lg"><span>Start free trial today</span></a></li>
                                    <li><a href="#" className="btn btn-primary btn-soft btn-lg"><em className="icon ni ni-play"></em><span>See action in video</span></a></li>
                                </ul>
                            </div>
                            <div className="section-content">
                                <div className="row gx-5 gy-6 align-items-center">
                                    <div className="col-lg-6 col-xl-6">
                                        <div className="block-gfx pe-xl-5 pe-lg-3">
                                            <img className="w-100 rounded-4" src="images/gfx/feature/a.jpg" alt=""/>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-xl-6">
                                        <div className="block-text">
                                            <h2 className="title">AI Generate content in seconds</h2>
                                            <p>Generate copy that converts for business bios, facebook ads, product descriptions, emails, landing pages, social ads, and more.</p>
                                            <ul className="list gy-3">
                                                <li><em className="icon ni ni-minus text-primary"></em><span>Create article that are complete in less than 15 seconds.</span></li>
                                                <li><em className="icon ni ni-minus text-primary"></em><span>Save hundreds of hours with our AI article generator.</span></li>
                                                <li><em className="icon ni ni-minus text-primary"></em><span>Improve your copy with the article rewriter.</span></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="section section-bottom-0 section-top-0">
                        <div className="container">
                            <div className="section-head">
                                <div className="row justify-content-center text-center">
                                    <div className="col-lg-9 col-xl-7 col-xxl-6">
                                        <h6 className="overline-title text-primary">Pricing</h6>
                                        <h2 className="title">Start your content writing with AI</h2>
                                        <p className="lead px-lg-10 px-xxl-9">With our simple plans, supercharge your content writing to helps your business.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="section-content">
                                <div className="row g-gs justify-content-center">
                                    <div className="col-lg-4 col-md-6">
                                        <div className="pricing">
                                            <div className="pricing-body p-5">
                                                <div className="text-center">
                                                    <h5 className="mb-3">Bronze</h5>
                                                    <h3 className="h2 mb-4">$9 <span className="caption-text text-muted"> / month</span></h3>
                                                    <button className="btn btn-primary btn-soft btn-block">Start free trial today</button>
                                                </div>
                                                <ul className="list gy-3 pt-4 ps-2">
                                                    <li><em className="icon ni ni-check text-success"></em> <span><strong>10,000</strong> Monthly Word Limit</span></li>
                                                    <li><em className="icon ni ni-check text-success"></em> <span><strong>10+</strong> Templates</span></li>
                                                    <li><em className="icon ni ni-check text-success"></em> <span>30+ Languages</span></li>
                                                    <li><em className="icon ni ni-check text-success"></em> <span>Advance Editor Tool</span></li>
                                                    <li><em className="icon ni ni-check text-success"></em> <span>Regular Technical Support</span></li>
                                                    <li><em className="icon ni ni-check text-success"></em> <span>Unlimited Logins</span></li>
                                                    <li><em className="icon ni ni-check text-success"></em> <span>Newest Features</span></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6">
                                        <div className="pricing pricing-featured bg-primary">
                                            <div className="pricing-body p-5">
                                                <div className="text-center">
                                                    <h5 className="mb-3">Silver</h5>
                                                    <h3 className="h2 mb-4">$19 <span className="caption-text text-muted"> / month</span></h3>
                                                    <button className="btn btn-primary btn-block">Start free trial today</button>
                                                </div>
                                                <ul className="list gy-3 pt-4 ps-2">
                                                    <li><em className="icon ni ni-check text-success"></em> <span><strong>20,000</strong> Monthly Word Limit</span></li>
                                                    <li><em className="icon ni ni-check text-success"></em> <span><strong>10+</strong> Templates</span></li>
                                                    <li><em className="icon ni ni-check text-success"></em> <span>50+ Languages</span></li>
                                                    <li><em className="icon ni ni-check text-success"></em> <span>Advance Editor Tool</span></li>
                                                    <li><em className="icon ni ni-check text-success"></em> <span>Regular Technical Support</span></li>
                                                    <li><em className="icon ni ni-check text-success"></em> <span>Unlimited Logins</span></li>
                                                    <li><em className="icon ni ni-check text-success"></em> <span>Newest Features</span></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6">
                                        <div className="pricing">
                                            <div className="pricing-body p-5">
                                                <div className="text-center">
                                                    <h5 className="mb-3">Diamond</h5>
                                                    <h3 className="h2 mb-4">$39 <span className="caption-text text-muted"> / month</span></h3>
                                                    <button className="btn btn-primary btn-soft btn-block">Start free trial today</button>
                                                </div>
                                                <ul className="list gy-3 pt-4 ps-2">
                                                    <li><em className="icon ni ni-check text-success"></em> <span><strong>50,000</strong> Monthly Word Limit</span></li>
                                                    <li><em className="icon ni ni-check text-success"></em> <span><strong>15+</strong> Templates</span></li>
                                                    <li><em className="icon ni ni-check text-success"></em> <span>70+ Languages</span></li>
                                                    <li><em className="icon ni ni-check text-success"></em> <span>Advance Editor Tool</span></li>
                                                    <li><em className="icon ni ni-check text-success"></em> <span>Regular Technical Support</span></li>
                                                    <li><em className="icon ni ni-check text-success"></em> <span>Unlimited Logins</span></li>
                                                    <li><em className="icon ni ni-check text-success"></em> <span>Newest Features</span></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="section section-bottom-0">
                        <div className="container">
                            <div className="section-head">
                                <div className="row justify-content-center text-center">
                                    <div className="col-xl-8">
                                        <h2 className="title">Frequently Asked Questions</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="section-content">
                                <div className="row g-gs justify-content-center">
                                    <div className="col-xl-9 col-xxl-8">
                                        <div className="accordion accordion-flush accordion-plus-minus accordion-icon-accent" id="faq-1">
                                            <div className="accordion-item">
                                                <h2 className="accordion-header">
                                                    <button className="accordion-button" data-bs-toggle="collapse" data-bs-target="#faq-1-1"> What is a copy? </button>
                                                </h2>
                                                <div id="faq-1-1" className="accordion-collapse collapse show" data-bs-parent="#faq-1">
                                                    <div className="accordion-body"> Yes, you can write long articel for your blog posts, product descriptions or any long article with CopyGen. We're always updating our template and tools, so let us know what are expecting! </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h2 className="accordion-header">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#faq-1-2"> Does CopyGen to write long articles? </button>
                                                </h2>
                                                <div id="faq-1-2" className="accordion-collapse collapse" data-bs-parent="#faq-1">
                                                    <div className="accordion-body"> Yes, you can write long articel for your blog posts, product descriptions or any long article with CopyGen. We're always updating our template and tools, so let us know what are expecting! </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h2 className="accordion-header">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#faq-1-3"> Is the generated content original? </button>
                                                </h2>
                                                <div id="faq-1-3" className="accordion-collapse collapse" data-bs-parent="#faq-1">
                                                    <div className="accordion-body"> Yes, you can write long articel for your blog posts, product descriptions or any long article with CopyGen. We're always updating our template and tools, so let us know what are expecting! </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h2 className="accordion-header">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#faq-1-4"> Do you have wordpress plugin? </button>
                                                </h2>
                                                <div id="faq-1-4" className="accordion-collapse collapse" data-bs-parent="#faq-1">
                                                    <div className="accordion-body"> Yes, you can write long articel for your blog posts, product descriptions or any long article with CopyGen. We're always updating our template and tools, so let us know what are expecting! </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="section section-bottom-0">
                        <div className="container">
                            <div className="section-wrap bg-primary bg-opacity-10 rounded-4">
                                <div className="section-content bg-pattern-dot-sm p-4 p-sm-6">
                                    <div className="row justify-content-center text-center">
                                        <div className="col-xl-8 col-xxl-9">
                                            <div className="block-text">
                                                <h6 className="overline-title text-primary">Boost your writing productivity</h6>
                                                <h2 className="title">End writer’s block today</h2>
                                                <p className="lead mt-3">It’s like having access to a team of copywriting experts writing powerful copy for you in 1-click.</p>
                                                <ul className="btn-list btn-list-inline">
                                                    <li><a href="#" className="btn btn-lg btn-primary"><span>Start writing for free</span><em className="icon ni ni-arrow-long-right"></em></a></li>
                                                </ul>
                                                <ul className="list list-row gy-0 gx-3">
                                                    <li><em className="icon ni ni-check-circle-fill text-success"></em><span>No credit card required</span></li>
                                                    <li><em className="icon ni ni-check-circle-fill text-success"></em><span>Cancel anytime</span></li>
                                                    <li><em className="icon ni ni-check-circle-fill text-success"></em><span>10+ tools to expolore</span></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
                <footer className="nk-footer">
                    <div className="section">
                        <div className="container">
                            <div className="row g-5">
                                <div className="col-xl-3 col-sm-4 col-6">
                                    <div className="wgs">
                                        <h6 className="wgs-title overline-title text-heading mb-3">Use Case</h6>
                                        <ul className="list gy-2 list-link-base">
                                            <li><a className="link-base" href="#">AI Writer</a></li>
                                            <li><a className="link-base" href="#">AI Articel Writer</a></li>
                                            <li><a className="link-base" href="#">Content Generator</a></li>
                                            <li><a className="link-base" href="#">AI Content Writing</a></li>
                                            <li><a className="link-base" href="#">Content Rewriter</a></li>
                                            <li><a className="link-base" href="#">Blog Post Writer</a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-xl-2 col-sm-4 col-6">
                                    <div className="wgs">
                                        <h6 className="wgs-title overline-title text-heading mb-3">About</h6>
                                        <ul className="list gy-2 list-link-base">
                                            <li><a className="link-base" href="#">Pricing</a></li>
                                            <li><a className="link-base" href="#">Login</a></li>
                                            <li><a className="link-base" href="#">Sign up</a></li>
                                            <li><a className="link-base" href="#">Privacy Policy</a></li>
                                            <li><a className="link-base" href="#">Terms &amp; Conditions</a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-xl-2 col-sm-4 col-6">
                                    <div className="wgs">
                                        <h6 className="wgs-title overline-title text-heading mb-3">CopyGen</h6>
                                        <ul className="list gy-2 list-link-base">
                                            <li><a className="link-base" href="#">Contact Us</a></li>
                                            <li><a className="link-base" href="#">FAQs</a></li>
                                            <li><a className="link-base" href="#">Affiliate Program</a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-7 col-md-9 me-auto order-xl-first">
                                    <div className="block-text">
                                        <a href="index.html" className="logo-link mb-4">
                                            <div className="logo-wrap">
                                                <img className="logo-img logo-dark" src="images/logo-dark.png" srcset="images/logo-dark2x.png 2x" alt=""/>
                                            </div>
                                        </a>
                                        <h4 className="title">Save time. Get inspired.</h4>
                                        <p>Automatically genarate blog articles, website copy, landing pages &amp; digital ads for your business in seconds. Unleash the most advanced AI writer to boost your traffic and productitvity. </p>
                                        <p className="text-heading mt-4">&copy; 2023 CopyGenAI. Template Made By <a target="_blank" href="https://softnio.com">Softnio</a> </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
    )
}

export default Home;